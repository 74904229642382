import React, { useContext, useEffect } from "react";
import { Context } from "redux/Store";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA } from "common/callouts";

import frontImage from "images/face/faceproducts/night-cream.webp";
import frontCapImage from "images/face/faceproducts/night-cream-capoff.webp";
import backImage from "images/face/faceproducts/night-cream-back.webp";
import frontZoomImage from "images/face/faceproducts/night-cream.webp";
import frontCapZoomImage from "images/face/faceproducts/night-cream-capoff.webp";
import backZoomImage from "images/face/faceproducts/night-cream-back.webp";

import img2 from "images/face/faceproducts/corneometer-hydration-chart.webp";
import img3 from "images/face/faceproducts/skin-maps.webp";

import img6 from "images/callouts/eucerin-face-product-family.webp";
import img7 from "images/callouts/cout-footer-daily-skin.webp";
//import img8 from "images/callouts/cout-footer-facecare.webp";
import img8 from "images/callouts/sunproducts2x.webp";
import "./nightcream.scss";

function EucerinFaceImmersiveHydrationNightCreamPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    setTimeout(() => {
      const myHash = window.location.hash.slice(1);
      const myScrollSpot = document.getElementById(myHash);
      if (myScrollSpot) {
        const currentBlock = myScrollSpot.getBoundingClientRect();
        window.scrollTo(0, currentBlock.top - 150);
      }
    }, 500);
  }, []);

  return (
    <Layout
      pageClass="page-face-eucerinfaceproducts-nightcream page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Eucerin Face Immersive Hydration Night Cream"
        pageDescription="Learn about Eucerin Sun Age Defense for aging skin"
      />

      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#106E9E"
              categoryName="FACE CARE"
              categoryFontColor="#ffffff"
              productName="Eucerin<sup>®</sup> Face Immersive Hydration
              Night Cream"
              productSlider={[frontImage, frontCapImage, backImage]}
              zoomImages={[frontZoomImage, frontCapZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold oceanicblue-text sub-title">
                  Provides long-lasting nighttime hydration
                </p>
                <p>
                  <span className="font-semi-bold">
                    Eucerin Face Immersive Hydration Night Cream
                  </span>{" "}
                  is formulated with{" "}
                  <span className="font-semi-bold">
                    <nobr>multi-weight hyaluronic acid</nobr> and antioxidant
                    complex
                  </span>{" "}
                  and leaves skin visibly smoother and more radiant.
                </p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>
                      Enriched with Provitamin B5 to condition skin and support
                      a visibly renewed appearance
                    </span>
                  </li>
                  <li>
                    <span>
                      Provides 24-hour moisture for a hydrated, smoother look
                    </span>
                  </li>
                  <li>
                    <span>
                      Diminishes the appearance of fine lines and wrinkles
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold oceanicblue-text sub-title">
                  Key ingredients
                </p>
                <p className="no-mb font-semi-bold">
                  Multi-weight hyaluronic acid
                </p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>
                      Intense moisture hydrates deep within the surface layers
                      of the skin
                    </span>
                  </li>
                </ul>
                <p className="no-mb font-semi-bold">Antioxidant complex</p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>
                      <span className="font-semi-bold">
                        Soy-Derived Glycine-Saponin
                      </span>{" "}
                      is an effective, natural substance that helps scavenge
                      free radicals
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="font-semi-bold">
                        Glycyrrhetinic Acid
                      </span>
                      , derived from roots of Chinese licorice plants, helps
                      defend the skin against free radicals
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="font-semi-bold">
                        Vitamin E (Tocopherol)
                      </span>{" "}
                      helps guard against free radical formation
                    </span>
                  </li>
                </ul>
                <p className="no-mb font-semi-bold">
                  Panthenol (Provitamin B5)
                </p>
                <ul className="oceanic-bullets">
                  <li>
                    <span>Softens and conditions skin</span>
                  </li>
                  <li>
                    <span> Binds water to support moisture</span>
                  </li>
                  <li>
                    <span>Supports a visibly renewed appearance</span>
                  </li>
                </ul>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold oceanicblue-text sub-title">
                    Formulated for all skin types
                  </p>
                  <ul className="checkmarks oceanicblue">
                    <li>
                      <span>NON-COMEDOGENIC</span>
                    </li>
                    <li>
                      <span>FRAGRANCE-FREE</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </section>
        </div>

        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="CLINICAL DATA"
              productThemeColor="oceanic-blue"
              id="clinicaldata"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Bar chart showing significant hydration in just 24 hours after using Night cream"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> Two-week
                    study to test moisturization efficacy following application
                    of Immersive Hydration Night Cream. Females (n=32), aged 35
                    to 65, with sensitive and/or dry skin. Product was applied
                    on the inner forearm after baseline assessments and subjects
                    returned for evaluation at 24 hours. Product was then
                    applied twice daily with final evaluation at Week 2.
                  </p>
                  <p className="references-symbols double last">
                    **Statistically significant improvement compared to baseline{" "}
                    (<span className="font-italic">p&lt;</span>0.001).
                  </p>
                  <p className="references-symbols last">
                    *Statistically significant improvement compared to control (
                    <span className="font-italic">p&lt;</span>0.001).
                  </p>
                  <p className="references last">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Significantly <br className="show-desktop" />
                    improved hydration <br className="show-desktop" />
                    in just 24 hours<sup>1</sup>
                  </div>
                </Col>
              </Row>
            </AccordionContent>

            <AccordionContent
              accordionHeading="CLINICAL DATA"
              productThemeColor="oceanic-blue"
              id="clinicaldata"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img3}
                    className="accordion-chart"
                    alt="Two images showing visibly smoother skin in 4 weeks after using Night cream"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> 42 female
                    subjects, 36-62 years of age, with self-assessed deep lines
                    and wrinkles in the eye area applied the product to the
                    assigned periorbital region (right or left) twice daily for
                    4 weeks; the opposite side remained untreated and served as
                    a control. Anti-wrinkle effect was determined by FOITS
                    <sub>HD</sub> measurements in the periorbital region in
                    comparison to an untreated control. The basis parameters
                    used to describe the periorbital regions were the average
                    depth of roughness (Rz) and the arithmetic mean roughness
                    (Ra).
                  </p>
                  <p className="references last">
                    FOITS<sub>HD</sub>=Fast Optical{" "}
                    <span className="font-italic">in-vivo</span> Topometry of
                    Human Skin in High Definition (allows for 3D mathematical
                    analysis of skin surface).
                  </p>

                  <p className="references">
                    <span className="font-bold">Reference: 1.</span> Data on
                    file. Beiersdorf Inc.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Visibly smoother&nbsp;
                    <br className="show-desktop" />
                    skin in 4 weeks<sup>1</sup>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
          </section>
        </div>

        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Eucerin face family product line"
                  caption="Find resources to support your face care recommendations"
                  buttonText="BROWSE RESOURCES"
                  buttonUrl="/resources"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img7}
                  imgAlt="EXPLORE BODY CARE"
                  caption="Clinically proven therapeutic solutions for daily skin care"
                  buttonText="EXPLORE BODY CARE"
                  buttonUrl="/body"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img8}
                  imgAlt="EXPLORE SUN CARE"
                  caption=" Sun protection for everyone under the sun"
                  buttonText="EXPLORE SUN CARE"
                  buttonUrl="/sun"
                  imgStyles={{ width: 236 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default EucerinFaceImmersiveHydrationNightCreamPage;
